<template>
  <div class="layout-with-top-bar column full-height no-wrap overflow-hidden-y">
    <!-- Top bar -->
    <div
      v-if="routeNameCheck($router?.currentRoute?.value?.name).isValidationsHeader"
      class="layout-with-top-bar__top-bar column no-wrap q-pa-md validations-header"
    >
      <slot name="top-bar"></slot>
    </div>
    <div
      v-else-if="!routeNameCheck($router?.currentRoute?.value?.name).isUnusableChoicesPage"
      class="layout-with-top-bar__top-bar column no-wrap q-pa-md"
    >
      <slot name="top-bar"></slot>
    </div>

    <!-- Content -->
    <div class="layout-with-top-bar__content overflow-hidden-y">
      <slot></slot>
    </div>
  </div>
</template>

<script>
  import { routeNameCheck } from '@/components/validations/utils'

  export default {
    name: 'PageLayoutWithTopBar',
    methods: {
      routeNameCheck
    }
  }
</script>

<style lang="scss" scoped>
  .layout-with-top-bar {
    min-height: inherit;

    &__top-bar {
      background-color: $mi-anthracite-50;
    }

    &__content {
      flex-grow: 1;
    }
  }

  .validations-header {
    height: fit-content;
    width: 100%;
    z-index: 1;
    background-color: #ffffff;
    padding: .5rem 1rem;
    box-shadow: 0 4px 4px rgba(0, 0, 0, .24);
    display: flex;
    flex-direction: row;
  }

  .validations-header-wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
</style>
