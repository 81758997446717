<template>
  <div class="feedback_content">
    <page-layout-with-top-bar>
      <template #top-bar>
        <div class="feedback_top-bar">
          <h5>MISSING A FEATURE?</h5>
          <p>Some features are no longer available for this version. Please, select one below and let us know why.</p>
        </div>
      </template>

      <template #default>
        <div class="feedback_body-div">
          <h4 class="menu-title">Feature selection</h4>
          <mi-form>
            <mi-list v-for="feat in featureOptions" :key="feat.id">
              <mi-separator v-if="feat.selected"></mi-separator>
              <mi-list-item class="missing-feature-option">
                <mi-checkbox
                  v-model="feat.selected"
                  :val="feat.selected"
                  color="black"
                  size="sm"
                >
                  <section class="q-ml-sm">
                    <p>{{ feat.title }}</p>
                    <p>{{ feat.description }}</p>
                  </section>
                </mi-checkbox>
              </mi-list-item>
              <mi-list-item v-if="feat.selected && feat.isNewFeature">
                <mi-list-item-section>
                  <strong>Need or feature</strong>
                  <mi-text-field
                    v-model="feat.featureTitle"
                    dense
                    outlined
                    square
                    placeholder="In my job I need to..."
                    counter
                    maxlength="600"
                    autogrow
                    :rules="[val => !!val || 'Field is required']"
                  ></mi-text-field>
                </mi-list-item-section>
              </mi-list-item>
              <mi-list-item v-if="feat.selected">
                <mi-list-item-section>
                  <strong>{{ feat.isNewFeature === true ? 'Details' : 'Reason' }}</strong>
                  <mi-text-field
                    v-model="feat.reason"
                    dense
                    outlined
                    square
                    placeholder="Enter the reasons why you need this feature"
                    counter
                    maxlength="600"
                    autogrow
                    :rules="[val => !!val || 'Field is required']"
                  ></mi-text-field>
                </mi-list-item-section>
              </mi-list-item>
            </mi-list>
            <div class="q-ml-md q-mt-lg">
              <mi-btn
                type="submit"
                :loading="loadingSubmission"
                :disable="isSubmitDisabled()"
                @click.prevent="submitFeedback"
              >
                Submit
              </mi-btn>
            </div>
          </mi-form>
        </div>
      </template>
    </page-layout-with-top-bar>
  </div>
</template>

<script>
  import { createMissingFeatureFeedback } from '@/api'
  import notify from '@/utils/notify'

  import PageLayoutWithTopBar from '@/layouts/PageLayoutWithTopBar.vue'
  import {
    MiSeparator,
    MiCheckbox,
    MiListItem,
    MiTextField,
    MiListItemSection,
    MiList,
    MiBtn,
    MiForm
  } from '@/packages/@mi-library'

  export default {
    name: 'UserFeedback',
    components: {
      PageLayoutWithTopBar,
      MiSeparator,
      MiCheckbox,
      MiListItem,
      MiTextField,
      MiListItemSection,
      MiList,
      MiBtn,
      MiForm
    },
    data: () => ({
      loadingSubmission: false,
      featureOptions: [
        {
          id: 'tree_visualisation',
          title: 'Tree visualisation of the variant combinations',
          description: 'A different result visualization from Variant management feature.',
          selected: false,
          isNewFeature: false,
          featureTitle: '',
          reason: ''
        },
        {
          id: 'rule_management',
          title: 'Rule management',
          description: 'Possibility to create additional rules that will be considered in Variant management.',
          selected: false,
          isNewFeature: false,
          featureTitle: '',
          reason: ''
        },
        {
          id: 'alias_management',
          title: 'Alias management',
          description: 'Possibility to combine different choices  under the same alias name.',
          selected: false,
          isNewFeature: false,
          featureTitle: '',
          reason: ''
        },
        {
          id: 'browse_porfolio',
          title: 'Browse portfolio',
          description: 'Finding elements through the portfolio without the search field.',
          selected: false,
          isNewFeature: false,
          featureTitle: '',
          reason: ''
        },
        {
          id: 'variant_management',
          title: 'Save collection on Variant management',
          description: 'Possibility to save a collection defined to search it in the future.',
          selected: false,
          isNewFeature: false,
          featureTitle: '',
          reason: ''
        },
        {
          id: 'other_feature',
          title: 'Other feature',
          description: 'Explain a need you have or another feature you are not finding.',
          selected: false,
          isNewFeature: true,
          featureTitle: '',
          reason: ''
        }
      ]
    }),
    methods: {
      async submitFeedback() {
        this.loadingSubmission = true

        const selectedFeedbacks = this.featureOptions.filter(feat => feat.selected === true).map(feat => ({
          id: feat.id,
          selectedOption: feat.title,
          featureTitle: feat.featureTitle,
          reason: feat.reason
        }))

        try {
          await createMissingFeatureFeedback({ selectedOptions: selectedFeedbacks })

          notify({
            title: 'Success',
            content: 'Your feedback has been submitted.',
            type: 'positive',
            progress: true
          })
        }
        catch (err) {
          notify({
            title: 'Something Happened',
            content: 'Your feedback was not submitted.',
            type: 'negative',
            progress: true
          })
        }

        this.loadingSubmission = false
        this.handleResetForm()
      },
      isSubmitDisabled() {
        const hasNotAnySelectedOption = this.featureOptions.filter(feat => feat.selected === true).length === 0

        const hasAnyInvalidInput = this.featureOptions.filter(feat => (feat.selected === true && feat.reason === '')
          || (feat.selected === true && feat.isNewFeature === true && feat.featureTitle === '')).length > 0

        return hasNotAnySelectedOption || hasAnyInvalidInput
      },
      handleResetForm() {
        this.featureOptions.forEach(feat => {
          feat.selected = false
          feat.reason = ''
          feat.featureTitle = ''
        })
      }
    }
  }

</script>

<style lang="scss" scoped>

  .feedback_content {
    overflow-y: visible;
    font-family: "Man Europe", sans-serif;
    font-style: normal;
    color: #303c49;
  }

  p {
    margin: 0;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }

  .feedback_top-bar {
    padding-left: 12%;

    h5 {
      margin: 0;
      font-weight: 700;
      font-size: 20px;
      line-height: 30px;
    }
  }

  .feedback_body-div {
    max-width: 790px;
    padding-left: 12%;

    h4 {
      font-weight: 400;
      font-size: 24px;
      line-height: 36px;
    }

    p + p {
      font-size: 14px;
      line-height: 21px;
      color: #8b99aa;
    }

    strong {
      margin-bottom: 8px;
      font-weight: 700;
      font-size: 14px;
      line-height: 14px;
    }

    mi-btn {
      padding: 12px 24px;
      gap: 12px;
      width: 103px;
      height: 50px;
      background: #3f4e5e;
      color: #ffffff;
      font-weight: 700;
      font-size: 18px;

      &:disabled {
        background: #d3d3d3;
        color: #afafaf;
      }
    }

    ::v-deep(.q-textarea .q-field__control) {
      height: auto;
    }
  }

  .missing-feature-option {
    padding: 16px;
  }
</style>
